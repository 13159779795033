import React, { useState, useEffect } from 'react';
import './bingo.css';
import ScaleText from "react-scale-text";
import ReactGA from "react-ga";
import QRCode from "qrcode.react";
import html2canvas from 'html2canvas';
import { useSearchParams } from "react-router-dom";
import * as comms from './comms.js';

function Game(props)  {
  var [isEditOn, setIsEditOn] = useState(false);
  var [isQROn, setIsQROn] = useState(false);
  var [reset, setReset] = useState(0);

  //these are the variables that make up the identiy of each board, not worrying about marker state for now
  var [boardId, setBoardId] = useState('');
  var [boardName, setBoardName] = useState('B I N G O');
  var [origText, setOrigText] = useState('');
  var [serverOrig, setServerOrig] = useState(false);
  var [parsedText, setParsedText] = useState('');
  var [boardText, setBoardText] = useState('');
  var [emptyBoard] = useState(new Array(5).fill('').map(() => new Array(5).fill('')));
  var [customId, setCustomId] = useState('');
  var [textComp, setTextComp] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log("++++Mount/Update+++++");
    let lBoardId = searchParams.get("board");
    let lCustId = searchParams.get("custom");
    if (!lBoardId) {
      if (props.board) {
        lBoardId = props.board;
      }
    }
    try {
      setBoardId(lBoardId);
      if (lCustId) {
        if (localStorage.getItem(lCustId)) {
          restoreBoard(lCustId);
        } else {
          comms.retCustomBoard(lCustId).then((b) => updateState(b));
          setCustomId(lCustId);
          setServerOrig(true);
        }
      } else if (lBoardId) {
        if (localStorage.getItem(lBoardId)) {
          restoreBoard(lBoardId);
        } else {
          comms.fetchBoardItems(lBoardId).then((b) => updateState(b));
          setBoardId(lBoardId);
          setServerOrig(true);
        }
      }
      if (origText && !boardText && !isEditOn) {
        updateBoard();
      }
    }
    catch (error) {
      console.log(error);
    }
    return () => {
      // console.log("-----Unmount-----");
    }
  }, [], [boardId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("====Mount/Update====");

    ReactGA.pageview(window.location.pathname + window.location.search);
    try {
      if (origText && !boardText && !isEditOn) {
        updateBoard();
      }
      if (boardText && searchParams.get("custom")) {
        saveBoard(searchParams.get("custom"));
      } else if (boardText && boardId && !isEditOn) {
        saveBoard(boardId);
      }
    }
    catch (error) {
      console.log(error);
    }
  })

  function saveImage(id) {
    html2canvas(document.querySelector(id)).then(function(canvas) {
      saveAs(canvas.toDataURL(), 'capture-' + id.substring(1) + '.png');
    });
  }

  // function clearBoard() {
  //   setBoardId("");
  //   setBoardName("B I N G O");
  //   setBoardText("");
  //   setOrigText("");
  //   setParsedText("");
  //   setServerOrig("");
  // }

  function saveBoard(saveId) {
    let storeObj = {
      boardId:boardId,
      boardName:boardName,
      origText:origText,
      parsedText:parsedText,
      boardText:boardText,
      serverOrig:serverOrig
    };
    localStorage.setItem(saveId, JSON.stringify(storeObj));
  }

  function restoreBoard(lBoardId) {
    let restoreObj = JSON.parse(localStorage.getItem(lBoardId));
    setBoardId(restoreObj.boardId);
    setBoardName(restoreObj.boardName);
    setBoardText(restoreObj.boardText);
    setOrigText(restoreObj.origText);
    setParsedText(restoreObj.parsedText);
    setServerOrig(restoreObj.serverOrig);
  }

  function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
    } else {
        window.open(uri);
    }
}

  function handleTextAreaChange(event) {
    // console.log("updating text");
    updateState(event.target.value);
    setServerOrig(false);
  }

  function updateState(text) {
    let parsed = parseText(text);
    setOrigText(prevText => text);
    setParsedText(prevParsed => parsed);
    return [text, parsed];
  }

  async function updateBoard(type) {
    let lOrigText = origText;
    let lParsedText = parsedText;
    if (boardText) {
      if (type === "reroll") {
        if (window.confirm("This will reroll your board, are you sure?")){
        } else {
          return(<div />);
        }
      } else if (type === "revert") {
        if (window.confirm("This will revert local changes you made to the board text, are you sure?")){
          lOrigText = await comms.fetchBoardItems(boardId);
          setServerOrig(true);
          lParsedText = updateState(lOrigText)[1];
        } else {
          return(<div />);
        }
      }
    }

    if (lParsedText) {
      let finalBoard = new Array(5).fill('').map(() => new Array(5).fill(''));
      let pos;
      let parsedBoard = lParsedText.slice();
      let length = parsedBoard.length;
      for (let i = parsedBoard.length - 1; i >= 0; i--) {
        let item = parsedBoard[i];
        if (item && item.length >= 2){
          if (item == null){break}
          if (item[1] === 't'){
            setBoardName(item[0]);
            parsedBoard.splice(i, 1);
            length = length-1;
            continue;
          }
          pos = spotSelector(item[1]);
          if (pos) {
            finalBoard[pos[0]][pos[1]] = item[0];
            if (length >= 25) {
              parsedBoard.splice(i, 1);
            }
          }
        }
      }

      finalBoard.forEach((item1, i) => {
        item1.forEach((item2, j) => {
          if (!item2) {
            let roll = Math.floor(Math.random() * parsedBoard.length);

            finalBoard[i][j] = parsedBoard[roll][0];
            if (length >= 25) {
              parsedBoard.splice(roll, 1);
            }
          }
        });
      });
      setBoardText(finalBoard);
      setReset(reset+1);
      localStorage.clear();
    }

    return(<div />);
  }

  function spotSelector(pos) {
    //console.log(pos);
    let row;
    let column;
    let lowPos = pos.toLowerCase().trim();
    if (lowPos === "f") {
      return [2, 2];
    }else if (lowPos.length === 2) {
      switch(lowPos.charAt(0)) {
        case 'b':
          column = 0;
          break;
        case 'i':
          column = 1;
          break;
        case 'n':
          column = 2;
          break;
        case 'g':
          column = 3;
          break;
        case 'o':
          column = 4;
          break;
        default:
          return null;
      }

      switch(lowPos.charAt(1)) {
        case '1':
          row = 0;
          break;
        case '2':
          row = 1;
          break;
        case '3':
          row = 2;
          break;
        case '4':
          row = 3;
          break;
        case '5':
          row = 4;
          break;
        default:
          return null;
      }
    }
    else {
      return null;
    }
    return [column,row];
  }

  function parseText(text) {
    var lines = text.split('\n');
    let words = [];
    for (let i = 0; i < lines.length; i++) {
      words[i] = lines[i].split(";");
    }
    return words;
  }

  function renderBoard() {
    let finalBoard;
    if (boardText) {
      finalBoard = boardText;
    }
    else {
      finalBoard = emptyBoard;
    }
    return (
      <div>
        {renderRow(finalBoard[0][0],finalBoard[1][0],finalBoard[2][0],finalBoard[3][0],finalBoard[4][0],1)}
        {renderRow(finalBoard[0][1],finalBoard[1][1],finalBoard[2][1],finalBoard[3][1],finalBoard[4][1],2)}
        {renderRow(finalBoard[0][2],finalBoard[1][2],finalBoard[2][2],finalBoard[3][2],finalBoard[4][2],3)}
        {renderRow(finalBoard[0][3],finalBoard[1][3],finalBoard[2][3],finalBoard[3][3],finalBoard[4][3],4)}
        {renderRow(finalBoard[0][4],finalBoard[1][4],finalBoard[2][4],finalBoard[3][4],finalBoard[4][4],5)}
      </div>
    );
  }

  function renderRow(b,i,n,g,o,pos) {
    let storeId;
    let lBoardId = searchParams.get("board");
    let lCustId = searchParams.get("custom");
    if (lCustId) {
      storeId = lCustId;
    } else if (lBoardId) {
      storeId = lBoardId;
    } else if (customId){
      storeId = customId;
    } else if (boardId) {
      storeId = boardId;
    } else if (props.board) {
      storeId = props.board;
    }
    return (
      <div className="board-row">
          {<MemoSquare text={b} pos={pos+10} reset={reset} storeId={storeId}/>}
          {<MemoSquare text={i} pos={pos+20} reset={reset} storeId={storeId}/>}
          {<MemoSquare text={n} pos={pos+30} reset={reset} storeId={storeId}/>}
          {<MemoSquare text={g} pos={pos+40} reset={reset} storeId={storeId}/>}
          {<MemoSquare text={o} pos={pos+50} reset={reset} storeId={storeId}/>}
        </div>
    );
  }

  function handleEdit() {
    if (isEditOn && textComp !== origText) {
      genCustomUrl();
    }
    setTextComp(origText);
    setIsEditOn(isEditOn => !isEditOn);
  }

  function handleQR() {
    setIsQROn(isQROn => !isQROn);
  }

  async function genCustomUrl() {
    if (serverOrig) return;
    var custId = await comms.insertCustomBoard(origText);
    setSearchParams({custom: custId});
    setCustomId(custId);
    setBoardId(null);
  }

  function makeQRCode(value) {
    return (
      <div>
        <QRCode className="QRCode" size={300} title={value} renderAs="svg" value={value} />
      </div>
    )
  }

  function waterMarker(element) {
    return (
      <div className="centerScreen">
        <div id="qrCode">{element}<div className="waterMark">www.event.bingo</div></div>
        <button className="downloadQRButton" onClick={() => saveImage("#qrCode")}>DOWNLOAD QRCODE</button>
      </div>
    )
  }

  function renderControls() {
    return (
      <div className="controls" data-html2canvas-ignore="true">
        <button className="control-buttons"  onClick={() => handleEdit()}>
          <ScaleText>
            <span>&#9998;</span>
          </ScaleText>
        </button>
        <button className="control-buttons"  onClick={() => updateBoard("reroll")}>
          <ScaleText>
            <span>&#x21bb;</span>
          </ScaleText>
        </button>
        <button className="control-buttons" onClick={() => saveImage("#board")}>
          <ScaleText>
            <span role="img" aria-label="save image">&#128190;</span>
          </ScaleText>
        </button>
        <button className="control-buttons" onClick={() => handleQR()}>
          <img className="share" alt="Share Button" src="/icons8-share.svg"/>
        </button>
      </div>
    )
  }

  const edit = isEditOn ? <textarea className = "edit" value={origText} onChange={handleTextAreaChange}></textarea> : null;
  // const edit = isEditOn ? <EditTextComponent updateState={updateState}/> :<div>{console.log("Closing Text Area")}</div>;

  const qrCode = isQROn ? <div>{waterMarker(makeQRCode(window.location.href))}</div> : null;
  return (
    <div id="testbest">
      <div className="game">
        <div className = "board-header" id="board">
          <div className="header">
            <div className="headerMark">www.event.bingo</div>
             {boardName}
          </div>
          <div className="game-board">
            {renderBoard()}
          </div>
        </div>
        {renderControls()}
        {edit}
        {qrCode}
      </div>
    </div>
  );
}

class Square extends React.Component {
  constructor(props) {
      super(props);
      this.state = {isToggleOn: false};
      this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    let key = this.props.pos;
    this.setState({isToggleOn: JSON.parse(localStorage.getItem(key + this.props.storeId))});
  }

  componentWillUnmount() {
    // let key = this.props.pos;
    // localStorage.setItem(key, JSON.stringify(this.state.isToggleOn));
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Square Update");
    if (this.state.isToggleOn) {
      if (prevProps.reset < this.props.reset) {
        this.setState({isToggleOn : false});
      }
    }
  }

  handleClick() {
    // console.log("Square Click");
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
    let key = this.props.pos;
    if (this.props.storeId) {
      localStorage.setItem(key + this.props.storeId, JSON.stringify(!this.state.isToggleOn));
    }

  }

  render() {
    const marker = this.state.isToggleOn ?<div className = "mark"></div> : null;
    return (
      <div className="container">
        <div className="testContainer">
          <button className="square" onClick={() => this.handleClick()}>
            <ScaleText>
              <p>{this.props.text}</p>
            </ScaleText>
            {marker}
          </button>
        </div>
      </div>
    );
  }
}

const MemoSquare = React.memo(Square);

export default Game;