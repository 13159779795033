import React from 'react';
import './index.css';
import Faq from './components/faq';
import Game from './components/bingo';
import Navbar from './components/navbar';
import Schedule from './components/schedule';
import Admin from './components/admin';

import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import Amplify from 'aws-amplify';
import awsExports  from './aws-exports';

const container = document.getElementById('root');
const root = createRoot(container);

var history = createBrowserHistory();

Amplify.configure(awsExports);

ReactGA.initialize('UA-35870321-2', {
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});

root.render(
  <div className="Main">
    <BrowserRouter history={history}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Game clear={false} key="2" board="ESAW2023"/>}/>
        <Route path="FAQ" element={<Faq />}/>
        <Route path="Schedule" element={<Schedule />}/>
        <Route path="create" element={<Game clear={true} key="1"/>}/>
        <Route path="admin" element={<Admin />}/>
        <Route path=":slug" element={<Game />} />
        <Route path="*" element={
          <main style={{padding: "2rem"}}>
          <p>Page Not Found</p>
          </main>}
        />
      </Routes>
    </BrowserRouter>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log());
