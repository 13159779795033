import React from 'react';
import { NavLink } from "react-router-dom";
import './navbar.css';

class Navbar extends React.Component {
  render() {
    return (
      <nav className="topnav">
        <div className ="topnavContainer">
          <NavLink
            exact="true"
            activeclassname="active"
            className="navButtons"
            to="/">
              <button type="button" className="navButton">Home</button>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navButtons"
            to="/schedule">
              <button type="button" className="navButton">Schedule</button>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navButtons"
            to="/FAQ">
              <button type="button" className="navButton">FAQ</button>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navButtons"
            to="/create">
              <button type="button" className="navButton">Create</button>
          </NavLink>

        </div>
      </nav>
    )
  }
}

export default Navbar;
