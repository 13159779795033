import React, { useState, useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import {Item, Provider, defaultTheme, ComboBox} from '@adobe/react-spectrum'
import "./admin.css"
import * as comms from './comms.js';

export default function Admin() {

  //constants
  const createName = "---Create---";

  //state

  let [serverBoards, setServerBoards] = useState([]);
  let [selectedBoard, setSelectedBoard] = useState('');
  let [selectedBoardObj, setSelectedBoardObj] = useState();
  let [textValue, setTextValue] = useState('');

  let [defaultBoardObj] = useState({
    trueName: "",
    event: "",
    startDate: "",
    endDate: "",
    category: "board",
    type: "",
    description: "",
    youTube: "",
    twitch: "",
    otherStream: ""
  });

  let [tmpBoardObj, setTmpBoardObj] = useState(defaultBoardObj);

  /**
   * Returns an array of objects containing the board events. The first object in the array represents a new board that can be created.
   * @function
   * @name listBoards
   * @returns {Array<Object>} An array of objects representing the board events, with the following properties:
   *  - {string} name: The name of the board event.
   */
  function listBoards() {
    let map = serverBoards.map((board, index) => ({"name":board.event}));
    map.unshift({name:createName});
    return map;
  }


  /**
   * Returns a string containing the text and positions of all items in a board.
   *
   * @param {Object} board - The board object to retrieve the text and positions from.
   * @return {string} A string containing the text and positions of all items in the board.
   */
  function getBoardText(board) {
    let text = '';
    let items = board.items.items;
    items.forEach((element, index) => {
      text = text.concat(element.text , ";" , element.position);
      if (index+1 !== items.length) {
        text = text.concat("\n");
      }
    });
    return text;
  }

  /**
   * Takes a text input and converts to a board object array. Used when saving new board text to the Graphql DB
   * @param {text} text text of a potential board
   * @param {text} board the board Id (event) to assicoate the items with 
   * @return {array} An array of objects that matches the format in the graphql DB.  Each object has two fields, text and position
   */
    function createBoardArray(text, board) {
    let boardObjArr = [];

    let lines = text.split("\n");
      lines.forEach((element, index) => {
        let splitLine = element.split(";");
        let tmpItem = {
          text: splitLine[0],
          position: splitLine[1],
          boardsItemsId: board
        };
        boardObjArr.push(tmpItem);
    });
    return boardObjArr;
  }

  /**
   * Handles the change event of a textarea element and sets the text value state.
   * @param {Object} event - The event object of the change event.
   * @return {void}
   */
  function handleTextAreaChange(event) {
    setTextValue(event.target.value);
  }


  /**
   * Compares two board arrays and checks the text and position against eachother
   * @param {array} arr1
   * @param {array} arr2  
   * @return {array} array containing elements in arr1 but not in arr2
   */
  function getArrDiff(arr1, arr2) {
    let diff = arr1.map(item => {
      let found = !arr2.every(element => {
        if ((item.text === element.text)) {
          return false;
        }
        return element;
      });
      if (!found){
        return item;
      }
      return null;
    });
    diff = diff.filter(element => element !== undefined);
    console.log("DIFF:", diff);
    return diff;
  }

  /**
   * Asynchronously handles the save of the current board.
   * Adds, updates, and deletes items as needed.
   * @async
   * @function handleSave
   * @returns {undefined}
   */
  async function handleSave() {
    console.log("handleSave");
    let selectedArr;
    let addItems;
    let deleteItems;
    if (selectedBoard && (selectedBoard === createName)) {
      await comms.addBoard(tmpBoardObj);
    }
    
    if (selectedBoard) {
      if (selectedBoardObj.items) {
        selectedArr = selectedBoardObj.items.items;
      } else { 
        selectedArr = [];
      }
      let boardObjArr = createBoardArray(textValue, tmpBoardObj.event);
      addItems = getArrDiff(boardObjArr, selectedArr);
      deleteItems = getArrDiff(selectedArr, boardObjArr);
      comms.updateBoard(tmpBoardObj);
      comms.batchAdd(addItems);
      comms.batchDelete(deleteItems);
    }
  }

  function handleDelete() {
    if (window.confirm("This will delete this board, are you sure?")){
      comms.deleteBoard(tmpBoardObj);
    } 
  }

  function handleTrueName(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      trueName: event.target.value
    });
  }

  function handleEvent(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      event: event.target.value
    });
  }

  function handleStartDate(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      startDate: event.target.value
    });
  }

  function handleEndDate(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      endDate: event.target.value
    });
  }

  function handleCategory(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      category: event.target.value
    });
  }

  function handleType(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      type: event.target.value
    });
  }

  function handleDescription(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      description: event.target.value
    });
  }

  function handleYouTube(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      youTube: event.target.value
    });
  }

  function handleTwitch(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      twitch: event.target.value
    });
  }

  function handleOtherStream(event) {
    setTmpBoardObj({
      ...tmpBoardObj,
      otherStream: event.target.value
    });
  }

  useEffect(() => {
    console.log("ADMIN PAGE LOADED");
  });

  useEffect(() => {
    console.log("ADMIN PAGE INITIAL LOADED");
    comms.fetchBoards(true).then((b) => setServerBoards(b));
  }, []);

  useEffect(() => {
     console.log("SELECTED board");
    if(selectedBoard === createName){
      console.log("create");
      setTmpBoardObj(defaultBoardObj);
      setSelectedBoardObj(defaultBoardObj);
      setTextValue("");
      return;
    }
    comms.fetchBoards(true).then((b) => {
      setServerBoards(b);
      let board = b.find(board => board.event === selectedBoard);
      for (const property in board) {
        if(board[property] === undefined) {
          board[property] = '';
        }
      }
      setSelectedBoardObj(board);
      if (board) {
        let text = getBoardText(board);
        setTextValue(text);
        setTmpBoardObj(board);
      }      
    });
  }, [selectedBoard, defaultBoardObj]);



  return (
    <>
      <Authenticator
      hideSignUp={true}
      className="auth">
        {({ signOut, user }) => (
          <main>
            <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button>
            <Provider theme={defaultTheme}>
            <ComboBox
              label="Pick a board"
              defaultItems={listBoards()}
              onSelectionChange={setSelectedBoard}
              defaultSelectedKey={0}>
              {item => <Item key={item.name}>{item.name}</Item>}
            </ComboBox>
            <div className="addBoardGroup"> 
              <div className="inputGroup">
                <label>True Name*</label><br/>
                <input value={tmpBoardObj.trueName} onChange={handleTrueName}></input><br/>
              </div>
              <div className="inputGroup">
                <label>Event ID*</label><br/>
                <input value={tmpBoardObj.event} onChange={handleEvent}></input><br/>
              </div>
              <br/>
              <div className="inputGroup">
                <label>Start Date*</label><br/>
                <input value={tmpBoardObj.startDate} type="datetime-local" onChange={handleStartDate}></input><br/>
              </div>
              <div className="inputGroup">
                <label>End Date</label><br/>
                <input value={tmpBoardObj.endDate} type="datetime-local" onChange={handleEndDate}></input><br/>
              </div>
              <br/>
              <div className="inputGroup">
                <label>Category*</label><br/>
                <input value={tmpBoardObj.category} onChange={handleCategory}></input><br/>
              </div>
              <div className="inputGroup">
                <label>Type*</label><br/>
                <input value={tmpBoardObj.type} onChange={handleType}></input><br/>
              </div>
              <div className="inputGroup">
                <label>Description</label><br/>
                <input value={tmpBoardObj.description} onChange={handleDescription}></input><br/>
              </div>
              <br/>
              <div className="inputGroup">
                <label>YouTube</label><br/>
                <input value={tmpBoardObj.youTube} onChange={handleYouTube}></input><br/>
              </div>
              <div className="inputGroup">
                <label>Twitch</label><br/>
                <input value={tmpBoardObj.twitch} onChange={handleTwitch}></input><br/>
              </div>
              <div className="inputGroup">
                <label>Other Stream</label><br/>
                <input value={tmpBoardObj.otherStream} onChange={handleOtherStream}></input><br/>
              </div>
            </div>
            <div className="buttonGroup">
              {/* <textarea classname = "boardName" value = {boardName} onChange={handleNameChange}>test</textarea> */}
              <button className="updateSave" onClick={() => handleSave()}>SAVE</button>
              <button className="deleteBoard" onClick={() => handleDelete()}>DELETE</button>

            </div>
            <textarea className = "editBox" value={textValue} onChange={handleTextAreaChange}></textarea>
            </Provider>
          </main>
        )}
      </Authenticator>
    </>
  );
}