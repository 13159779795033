import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';


/**
 * Returns the names of the boards in the DB
 * @param   {boolean} getAll Get all items or ones after curren date
 * @return  {array} An array of the board item and its components
 */

export async function fetchBoards(getAll) {
  var apiData = null;
  try {
    apiData = await API.graphql(graphqlOperation(queries.listBoards));
    if (apiData) {
      const items = apiData.data.listBoards.items;
      let currDate = new Date();
      let evntDate;
      if (!getAll) {
        for (let i = items.length - 1; i >= 0; i--) {
          let item = items[i];
          if (item.endDate) {
            evntDate = new Date(item.endDate);
            if (evntDate < currDate) {
              items.splice(i, 1);
            }
          } else if (item.startDate) {
            evntDate = new Date(item.startDate);
            evntDate.setDate(evntDate.getDate() + 1);
            if ((evntDate) < currDate) {
              items.splice(i, 1);
            }
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
  if (apiData) {
      return apiData.data.listBoards.items;
  }
  return [];
}

export async function fetchBoardItems(boardName) {
  let words = [];
  let text = '';
  try {
    const apiData = await API.graphql(graphqlOperation(queries.listBoards, { event: boardName}));
   if (apiData.data.listBoards.items.length !== 0) {
      let textItems = apiData.data.listBoards.items[0].items;
      for (let i = 0; i < textItems.items.length; i++) {
        words.push([textItems.items[i].text, textItems.items[i].position]);
        if (textItems.items[i].position) {
          text = text.concat(textItems.items[i].text, ";", textItems.items[i].position);
        } else {
          text = text.concat(textItems.items[i].text);
        }
        if (i < textItems.items.length -1){
          text = text.concat("\n");
        }
      }
    }
  } catch (error){
    console.log(error);
  }
  return text;
}

export async function insertCustomBoard(board) {
  var randomBytes = require('randombytes');
  var custId = 0;
  var apiData = {
    data: {
      getUrlStorage:1
    }
  };
  try {
    while(apiData.data.getUrlStorage) {
      custId = randomBytes(4).readUInt32BE(0, true);
      apiData = await API.graphql(graphqlOperation(queries.getUrlStorage, {url:custId}));
    }
    await API.graphql(graphqlOperation(mutations.createUrlStorage, {input: {url:custId, board: JSON.stringify(board)}}));
  } catch (error) {
    console.log(error);
  }
  return custId.toString(16);
}

export async function retCustomBoard(id) {
  if (typeof(id) == "string") {
    id = parseInt(id, 16);
  }
  try {
    var custBoard = await API.graphql(graphqlOperation(queries.getUrlStorage, {url: id}));
  } catch(error) {
    console.log(error);
  }
  return JSON.parse(custBoard.data.getUrlStorage.board);
}

/**
 * Add an array of bingo items
 * @param {array} items Array of objects with text, position and board
 */
export async function batchAdd(items) {
  items.forEach(element => {
    addItem(element);
  });
}

/**
 * Add an item to the graphql db of the board
 * @param {object} item Bingo item object containing board, position and board
 */
export async function addItem(item) {
  let res;

  if (!item.text || !item.boardsItemsId)
      return;

  const createDetails = {
    text: item.text,
    position: item.position,
    boardsItemsId: item.boardsItemsId
  };

  try {
    res = await API.graphql({
      query: mutations.createItems,
      variables: {input: createDetails},
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
  } catch(error) {
    console.log(error);
  }
  return res;
}

/**
 * Delete multiple items from a bingo board
 * @param {array} items Items to be deleted from a board
 */
export async function batchDelete(items) {
  items.forEach(element => {
    deleteItem(element);
  });
}

/**
 * 
 * @param {object} item Bingo item containing text, position and board
 */
export async function deleteItem(item) {
  let res;
  const deleteDetails = {
    id: item.id
  };

  try {
    res = await API.graphql({
      query: mutations.deleteItems,
      variables: {input: deleteDetails},
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
  } catch(error) {
    console.log(error);
  }
  return res;
}

export async function addBoard(board) {
  let res;
  const createDetails = {
    event: board.event,
    trueName: board.trueName,
    startDate: board.startDate,
    endDate: board.endDate,
    category: board.category,
    type: board.type,
    description: board.description,
    youTube: board.youTube,
    twitch: board.twitch,
    otherStream: board.otherStream
  }

  try {
    res = await API.graphql({
      query: mutations.createBoards,
      variables: {input: createDetails},
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
  } catch(error) {
    console.log(error);
  }
  return res
}

export async function deleteBoard(board) {
  let res;
  const deleteBoard = {
    event: board.event
  }
  try {
    res = await API.graphql({
      query: mutations.deleteBoards,
      variables: {input: deleteBoard},
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
  } catch(error) {
    console.log(error);
  }
  return res;
}

export async function updateBoard(board) {
  let res;
  const updateBoard = {
    event: board.event,
    trueName: board.trueName,
    startDate: board.startDate,
    endDate: board.endDate,
    category: board.category,
    type: board.type,
    description: board.description,
    youTube: board.youTube,
    twitch: board.twitch,
    otherStream: board.otherStream
  }
  try {
    res = await API.graphql({
      query: mutations.updateBoards,
      variables: {input: updateBoard},
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
  } catch(error) {
    console.log(error);
  }
  return res;
}