import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import './schedule.css';
import * as comms from './comms.js';
import { Link } from "react-router-dom";

function Schedule() {
  const [gameBoards, setGameBoards] = useState([]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    comms.fetchBoards(false).then((b) => setGameBoards(b));
  }, []);


  function getDateObj(dateStr) {
    var dateObj = new Date(dateStr);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit'};
    return (
      <span>{dateObj.toLocaleDateString("en-us", options)}</span>
    )
  }

  function Stream(props) {
    if (props.url) {
      return <p><a href={props.url}>Stream</a></p>;
    }
    return <div />;
  }

  function BoardLink(props) {
    try {
      if (props.items.items.items.length) {
        return <Link to={{
          pathname:"/",
          search: "board=" + props.items.event,
        }}><h3>{props.items.trueName}</h3></Link>
      }
    }catch (error) {
      console.log(error);
    }
    return <h3>{props.items.trueName} (Coming Soon)</h3>;
  }

  return(
    <div className="schedule">
      <h2>Schedule</h2>
      {
          gameBoards.map(board => (
            <div key={board.id || board.trueName}>
              <BoardLink items={board} />
              <p>{board.description}</p>
              <p>Starts: {getDateObj(board.startDate)}</p>
              <Stream url={board.otherStream} />

            </div>
          ))
        }
    </div>
  )
}

export default Schedule
