import React from 'react';
import './faq.css';
import ReactGA from 'react-ga';
// ReactGA.initialize('UA-35870321-2', {
//   debug: true,
//   titleCase: false,
//   gaOptions: {
//     userId: 123
//   }
// });

class Faq extends React.Component {

  componentDidMount () {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="faq">
        <h1>About</h1>
        <h3>What is event.bingo?</h3>
        <p>Event.bingo is a bingo site designed for live events. The goal is to have a lightweight bingo site that users can easily create bingo boards that can be shared on message boards or social media.</p>

        <h3>What kind of events is this meant for?</h3>
        <p>Any live event, but would be good for live conferences, politcal events, sporting events, videogame events. Basically any event where you can speculate on what is going to happen.</p>

        <h3>How do I play?</h3>
        <p>Check the schedule to see if we have a board for an event youre interested, come back when the event is on and start playing. You can also create your own and share it with your friends.</p>

        <h3>Got a Discord or other contacts?</h3>
        <p>Discord: <a href="https://discord.gg/WEtuhnaCsG">Click here</a></p>
        <p>Twitter: @EvntBngo</p>
        <p>Email: admin@event.bingo</p>

        <h3>Why is it buggy?</h3>
        <p>This is a project I did for fun and is still being developed. If there are issues let me know in discord or just email admin@event.bingo</p>

        <h1>Usage</h1>
        <h3>How to I create my own board</h3>
        <p>Hit the edit button &#9998; and start editing. For a blank board hit "Create" in the navbar, or build off an existing board.</p>
        <p>When editing, each line will be its own space. You may specify which space it will appear in by adding a semicolon ; to the end of each space. Then specify where you want the space to appear. Valid options are f (freespace), t (title, will not appear in board), b[1-5], i[1-5], n[1-5], g[1-5], o[1-5]. See example below.</p>
        <div className="codeConainer">
          <code className="code">This is space<br/></code>
          <code className="code">This is a different space<br/></code>
          <code className="code">This will be the free space; f<br/></code>
          <code className="code">This will be in the top right corner; o1<br/></code>
          <code className="code">This will be second from the top in the second column; i2<br/></code>
          <code className="code">This will be in the bottom right corner; o5<br/></code>
          <code className="code">This will be the title of the board; t<br/></code>
        </div>
        <p>When you finish editing, hit the edit button &#9998; to close the edit window. Now you must reroll &#x21bb; your board to have the changes reflect. Also, a new custom URL will be created and shown in the address bar. This will link to the board as it currently exists.</p>

        <h3>What do the buttons do?</h3>
        <p>&#9998;EDIT: Edit the board, see above</p>
        <p>&#x21bb; REROLL: Rerolls the board, run after editing</p>
        <p><span role="img" aria-label="save image">&#128190;</span> SAVE: Save an image as the board currently esists</p>
        <p>&#x238C; REVERT: Revert the board back to server default, WILL REVERT CUSTOM CHANGES</p>
        <p><img className="share" alt="Share Button" src="/icons8-share.svg"/> SHARE: Get a QR code that can be used to link to the board, works with custom boards.</p>
      </div>
    )
  }
}

export default Faq;
