/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBoards = /* GraphQL */ `
  query GetBoards($event: String!) {
    getBoards(event: $event) {
      event
      category
      type
      startDate
      endDate
      trueName
      description
      youTube
      twitch
      otherStream
      items {
        items {
          id
          text
          position
          type
          createdAt
          updatedAt
          boardsItemsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $event: String
    $filter: ModelBoardsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBoards(
      event: $event
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        event
        category
        type
        startDate
        endDate
        trueName
        description
        youTube
        twitch
        otherStream
        items {
          items {
            id
            text
            position
            type
            createdAt
            updatedAt
            boardsItemsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byOrderByDate = /* GraphQL */ `
  query ByOrderByDate(
    $category: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelboardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byOrderByDate(
      category: $category
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        event
        category
        type
        startDate
        endDate
        trueName
        description
        youTube
        twitch
        otherStream
        items {
          items {
            id
            text
            position
            type
            createdAt
            updatedAt
            boardsItemsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getItems = /* GraphQL */ `
  query GetItems($id: ID!) {
    getItems(id: $id) {
      id
      text
      position
      type
      createdAt
      updatedAt
      boardsItemsId
      owner
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $id: ID
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        text
        position
        type
        createdAt
        updatedAt
        boardsItemsId
        owner
      }
      nextToken
    }
  }
`;
export const getUrlStorage = /* GraphQL */ `
  query GetUrlStorage($url: Float!) {
    getUrlStorage(url: $url) {
      url
      board
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUrlStorages = /* GraphQL */ `
  query ListUrlStorages(
    $url: Float
    $filter: ModelUrlStorageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUrlStorages(
      url: $url
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        url
        board
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
