/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBoards = /* GraphQL */ `
  mutation CreateBoards(
    $input: CreateBoardsInput!
    $condition: ModelBoardsConditionInput
  ) {
    createBoards(input: $input, condition: $condition) {
      event
      category
      type
      startDate
      endDate
      trueName
      description
      youTube
      twitch
      otherStream
      items {
        items {
          id
          text
          position
          type
          createdAt
          updatedAt
          boardsItemsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBoards = /* GraphQL */ `
  mutation UpdateBoards(
    $input: UpdateBoardsInput!
    $condition: ModelBoardsConditionInput
  ) {
    updateBoards(input: $input, condition: $condition) {
      event
      category
      type
      startDate
      endDate
      trueName
      description
      youTube
      twitch
      otherStream
      items {
        items {
          id
          text
          position
          type
          createdAt
          updatedAt
          boardsItemsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBoards = /* GraphQL */ `
  mutation DeleteBoards(
    $input: DeleteBoardsInput!
    $condition: ModelBoardsConditionInput
  ) {
    deleteBoards(input: $input, condition: $condition) {
      event
      category
      type
      startDate
      endDate
      trueName
      description
      youTube
      twitch
      otherStream
      items {
        items {
          id
          text
          position
          type
          createdAt
          updatedAt
          boardsItemsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createItems = /* GraphQL */ `
  mutation CreateItems(
    $input: CreateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    createItems(input: $input, condition: $condition) {
      id
      text
      position
      type
      createdAt
      updatedAt
      boardsItemsId
      owner
    }
  }
`;
export const updateItems = /* GraphQL */ `
  mutation UpdateItems(
    $input: UpdateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    updateItems(input: $input, condition: $condition) {
      id
      text
      position
      type
      createdAt
      updatedAt
      boardsItemsId
      owner
    }
  }
`;
export const deleteItems = /* GraphQL */ `
  mutation DeleteItems(
    $input: DeleteItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    deleteItems(input: $input, condition: $condition) {
      id
      text
      position
      type
      createdAt
      updatedAt
      boardsItemsId
      owner
    }
  }
`;
export const createUrlStorage = /* GraphQL */ `
  mutation CreateUrlStorage(
    $input: CreateUrlStorageInput!
    $condition: ModelUrlStorageConditionInput
  ) {
    createUrlStorage(input: $input, condition: $condition) {
      url
      board
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUrlStorage = /* GraphQL */ `
  mutation UpdateUrlStorage(
    $input: UpdateUrlStorageInput!
    $condition: ModelUrlStorageConditionInput
  ) {
    updateUrlStorage(input: $input, condition: $condition) {
      url
      board
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUrlStorage = /* GraphQL */ `
  mutation DeleteUrlStorage(
    $input: DeleteUrlStorageInput!
    $condition: ModelUrlStorageConditionInput
  ) {
    deleteUrlStorage(input: $input, condition: $condition) {
      url
      board
      createdAt
      updatedAt
      owner
    }
  }
`;
